<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-row>
      <b-col md="12">
        <b-card>
          <b-col
            md="12"
            class="b-col-custom"
          >
            <div class="d-flex align-items-center justify-content-start">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="d-flex mr-2"
                @click="saveCategory()"
              >
                <span class="align-middle">{{ $t('Save') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card
          class="chat-widget"
          no-body
        >
          <b-card-header>
            <div class="d-flex align-items-center">
              <b-avatar
                v-if="selected1"
                size="34"
                :src="selected1.image"
                class="mr-50"
              />
              <b-avatar
                v-else
                size="34"
                :src="require('@/assets/images/blank/no_image.png')"
                class="mr-50"
              />
              <b-col>
                <h5
                  v-if="selected1"
                  class="mb-0"
                >
                  {{ selected1.name[0].value }}
                </h5>
                <h5
                  v-else
                  class="mb-0"
                >
                  {{ $t('Not Selected') }}
                </h5>
                <h5 class="mb-0">
                  {{ $t('Category level') }} : 1
                </h5>
              </b-col>
            </div>
          </b-card-header>
          <section class="chat-app-window">
            <vue-perfect-scrollbar
              ref="refChatLogPS1"
              class="user-chats scroll-area"
              :options="perfectScrollbarSettings"
            >
              <!-- Filters -->
              <b-list-group class="list-group-filters">
                <b-form-input
                  v-model="searchQuery1"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search') + '...'"
                />
                <b-list-group-item disabled>
                  <b-row>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('Image') }}
                      </p>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('RefId') }}
                      </p>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('Name') }}
                      </p>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item
                  v-for="filter in category1"
                  :key="filter._id"
                  :active="activeList1(filter)"
                  @click="selectCategory(filter, 0)"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <b-avatar
                        v-if="filter.image"
                        size="34"
                        :src="filter.image"
                        class="mr-50"
                      />
                      <b-avatar
                        v-else
                        size="34"
                        :src="require('@/assets/images/blank/no_image.png')"
                        class="mr-50"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <p class="align-text-bottom line-height-1">
                        {{ filter.refId }}
                      </p>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <p
                        v-for="(item, index) in filter.name"
                        :key="index"
                        class="align-text-bottom line-height-1"
                      >
                        {{ item.lang.toUpperCase() }} : {{ item.value }}
                      </p>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </vue-perfect-scrollbar>
          </section>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card
          class="chat-widget"
          no-body
        >
          <b-card-header>
            <div class="d-flex align-items-center">
              <b-avatar
                v-if="selected2"
                size="34"
                :src="selected2.image"
                class="mr-50"
              />
              <b-avatar
                v-else
                size="34"
                :src="require('@/assets/images/blank/no_image.png')"
                class="mr-50"
              />
              <b-col>
                <h5
                  v-if="selected2"
                  class="mb-0"
                >
                  {{ selected2.name[0].value }}
                </h5>
                <h5
                  v-else
                  class="mb-0"
                >
                  {{ $t('Not Selected') }}
                </h5>
                <h5 class="mb-0">
                  {{ $t('Category level') }} : 2
                </h5>
              </b-col>
            </div>
          </b-card-header>
          <section class="chat-app-window">
            <vue-perfect-scrollbar
              ref="refChatLogPS1"
              class="user-chats scroll-area"
              :options="perfectScrollbarSettings"
            >
              <!-- Filters -->
              <b-list-group class="list-group-filters">
                <b-form-input
                  v-model="searchQuery2"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search') + '...'"
                />
                <b-list-group-item disabled>
                  <b-row>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('Image') }}
                      </p>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('RefId') }}
                      </p>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('Name') }}
                      </p>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item
                  v-for="filter in category2"
                  :key="filter._id"
                  :active="activeList2(filter)"
                  @click="selectCategory(filter, 1)"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <b-avatar
                        v-if="filter.image"
                        size="34"
                        :src="filter.image"
                        class="mr-50"
                      />
                      <b-avatar
                        v-else
                        size="34"
                        :src="require('@/assets/images/blank/no_image.png')"
                        class="mr-50"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <p class="align-text-bottom line-height-1">
                        {{ filter.refId }}
                      </p>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <p
                        v-for="(item, index) in filter.name"
                        :key="index"
                        class="align-text-bottom line-height-1"
                      >
                        {{ item.lang.toUpperCase() }} : {{ item.value }}
                      </p>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </vue-perfect-scrollbar>
          </section>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card
          class="chat-widget"
          no-body
        >
          <b-card-header>
            <div class="d-flex align-items-center justify-content-start">
              <b-avatar
                v-if="selected3"
                size="34"
                :src="selected3.image"
                class="mr-50"
              />
              <b-avatar
                v-else
                size="34"
                :src="require('@/assets/images/blank/no_image.png')"
                class="mr-50"
              />
              <b-col>
                <h5
                  v-if="selected3"
                  class="mb-0"
                >
                  {{ selected3.name[0].value }}
                </h5>
                <h5
                  v-else
                  class="mb-0"
                >
                  {{ $t('Not Selected') }}
                </h5>
                <h5 class="mb-0">
                  {{ $t('Category level') }} : 3
                </h5>
              </b-col>
            </div>
          </b-card-header>
          <section class="chat-app-window">
            <vue-perfect-scrollbar
              ref="refChatLogPS1"
              class="user-chats scroll-area"
              :options="perfectScrollbarSettings"
            >
              <!-- Filters -->
              <b-list-group class="list-group-filters">
                <b-form-input
                  v-model="searchQuery3"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search') + '...'"
                />
                <b-list-group-item disabled>
                  <b-row>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('Image') }}
                      </p>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('RefId') }}
                      </p>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('Name') }}
                      </p>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item
                  v-for="filter in category3"
                  :key="filter._id"
                  :active="activeList3(filter)"
                  @click="selectCategory(filter, 2)"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <b-avatar
                        v-if="filter.image"
                        size="34"
                        :src="filter.image"
                        class="mr-50"
                      />
                      <b-avatar
                        v-else
                        size="34"
                        :src="require('@/assets/images/blank/no_image.png')"
                        class="mr-50"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <p class="align-text-bottom line-height-1">
                        {{ filter.refId }}
                      </p>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <p
                        v-for="(item, index) in filter.name"
                        :key="index"
                        class="align-text-bottom line-height-1"
                      >
                        {{ item.lang.toUpperCase() }} : {{ item.value }}
                      </p>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </vue-perfect-scrollbar>
          </section>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BAvatar,
  BButton,
  BCard,
  BCardHeader,
  BLink,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

import storeModuleProduct from '@/store/services/hero/product'
import storeModuleCategory from '@/store/services/hero/productCategory'
import debounce from 'debounce'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const STORE_MODULE_PRODUCT_NAME = 'product'
const STORE_MODULE_CATEGORY_NAME = 'productCategory'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BAvatar,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    VuePerfectScrollbar,
    // eslint-disable-next-line vue/no-unused-components
    PerfectScrollbar,
    // eslint-disable-next-line vue/no-unused-components
    BCard,
    BCardHeader,
    // eslint-disable-next-line vue/no-unused-components
    BLink,
    BListGroup,
    BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      isSidebarActive: false,
      sidebarData: {},
      isSelectedCategory: null,
      searchQuery1: '',
      searchQuery2: '',
      searchQuery3: '',
      selected1: null,
      selected2: null,
      selected3: null,
      perfectScrollbarSettings: {
        maxScrollbarLength: 10,
        wheelPropagation: true,
      },
      currentPage: 1,
    }
  },
  computed: {
    respData() {
      return store.state[STORE_MODULE_CATEGORY_NAME].respData != null
        ? store.state[STORE_MODULE_CATEGORY_NAME].respData.data
        : []
    },
    category1() {
      const regex = new RegExp(this.searchQuery1, 'i')
      const result = this.respData.filter(
        p => p.position === 0
          && (regex.test(p.refId) || regex.test(p.name.map(n => n.value))),
      )
      return result || []
    },
    category2() {
      let result = []
      const regex = new RegExp(this.searchQuery2, 'i')

      if (this.selected1) {
        result = this.respData.filter(
          p => p.parent_id === this.selected1.id
            && (regex.test(p.refId) || regex.test(p.name.map(n => n.value))),
        )
      }
      return result || []
    },
    category3() {
      let result = []
      const regex = new RegExp(this.searchQuery3, 'i')

      if (this.selected2) {
        result = this.respData.filter(
          p => p.parent_id === this.selected2.id
            && (regex.test(p.refId) || regex.test(p.name.map(n => n.value))),
        )
      }
      return result || []
    },
    productCategory() {
      const result = []
      if (this.selected1) {
        result.push(this.selected1)
        if (this.selected2) {
          result.push(this.selected2)
          if (this.selected3) {
            result.push(this.selected3)
          }
        }
      }
      return result || []
    },
    queriedItems() {
      return store.state[STORE_MODULE_CATEGORY_NAME].respData != null
        ? store.state[STORE_MODULE_CATEGORY_NAME].respData.max
        : 0
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_CATEGORY_NAME)) store.registerModule(STORE_MODULE_CATEGORY_NAME, storeModuleCategory)
    if (!store.hasModule(STORE_MODULE_PRODUCT_NAME)) store.registerModule(STORE_MODULE_PRODUCT_NAME, storeModuleProduct)
    const obj = {
      currentPage: this.currentPage,
      pageSize: 0,
      searchQuery: '',
    }

    this.show = true
    store
      .dispatch(`${STORE_MODULE_CATEGORY_NAME}/get`, obj)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        //
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
      })
      .catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    store
      .dispatch(`${STORE_MODULE_PRODUCT_NAME}/view`, {
        id: this.$route.params.id,
      })
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        const productResult = result.data.data.productCategory
        if (productResult.length > 0) {
          if (productResult.length === 3) {
            // eslint-disable-next-line prefer-destructuring
            this.selected1 = productResult[0]
              && Object.keys(productResult[0]).length === 0
              && Object.getPrototypeOf(productResult[0]) === Object.prototype
              ? null
              : productResult[0]
            // eslint-disable-next-line prefer-destructuring
            this.selected2 = productResult[1]
              && Object.keys(productResult[1]).length === 0
              && Object.getPrototypeOf(productResult[1]) === Object.prototype
              ? null
              : productResult[1]
            // eslint-disable-next-line prefer-destructuring
            this.selected3 = productResult[2]
              && Object.keys(productResult[2]).length === 0
              && Object.getPrototypeOf(productResult[2]) === Object.prototype
              ? null
              : productResult[2]
          } else if (productResult.length === 2) {
            // eslint-disable-next-line prefer-destructuring
            this.selected1 = productResult[0]
              && Object.keys(productResult[0]).length === 0
              && Object.getPrototypeOf(productResult[0]) === Object.prototype
              ? null
              : productResult[0]
            // eslint-disable-next-line prefer-destructuring
            this.selected2 = productResult[1]
              && Object.keys(productResult[1]).length === 0
              && Object.getPrototypeOf(productResult[1]) === Object.prototype
              ? null
              : productResult[1]
          } else if (productResult.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            this.selected1 = productResult[0]
              && Object.keys(productResult[0]).length === 0
              && Object.getPrototypeOf(productResult[0]) === Object.prototype
              ? null
              : productResult[0]
          }
        }
        //
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
      })
      .catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    this.typing = debounce(this.typing, 500)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_CATEGORY_NAME)) store.unregisterModule(STORE_MODULE_CATEGORY_NAME)
    if (store.hasModule(STORE_MODULE_PRODUCT_NAME)) store.unregisterModule(STORE_MODULE_PRODUCT_NAME)
  },
  setup() {
    return { STORE_MODULE_CATEGORY_NAME, STORE_MODULE_PRODUCT_NAME }
  },
  methods: {
    activeList3(value) {
      if (this.selected3) {
        if (this.selected3._id === value._id) {
          return true
        }
        return false
      }
      return false
    },
    activeList2(value) {
      if (this.selected2) {
        if (this.selected2._id === value._id) {
          return true
        }
        return false
      }
      return false
    },
    activeList1(value) {
      if (this.selected1) {
        if (this.selected1._id === value._id) {
          return true
        }
        return false
      }
      return false
    },
    selectCategory(value, position) {
      if (position === 0) {
        this.selected1 = value
        this.selected2 = null
        this.selected3 = null
      } else if (position === 1) {
        this.selected2 = value
        this.selected3 = null
      } else if (position === 2) {
        this.selected3 = value
      }
    },
    saveCategory() {
      const obj = {
        id: this.$route.params.id,
        productCategory: this.productCategory.map(p => p._id),
      }
      store
        .dispatch(`${this.STORE_MODULE_PRODUCT_NAME}/updateCategory`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          // this.showToast('success', 'bottom-right')
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'CheckIcon',
              variant: 'success',
              text: this.$t('Success'),
            },
          })
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'bottom-right')
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/base/pages/app-chat-list.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.scroll-area {
  // position: relative;
  // margin: auto;
  // width: 400px;
  height: 60vh;
}
.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.b-col-custom {
  margin-top: 0.5rem;
}
</style>
